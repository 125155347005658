<template>
    <div class="box">
        <!-- 头部 -->
        <headers></headers>
        <el-carousel :interval="3000">
            <el-carousel-item>
                 <img src="@/assets/homePage/关于盛泰banner.png" class="images">
            </el-carousel-item>
        </el-carousel>
        <!-- 中间内容区域 -->
        <div class="content">
            <h2 class="H2">技术研发</h2>
            <img class="img_1" src="@/assets/about/research/技术研发.png" alt="">
            <div class="box_1"></div>
            <div class="text" v-for="item in dataList" :key="item.id">
                <h2>{{ item.name }}</h2>
                <p>{{ item.title }}</p>
            </div>
            <div class="imgs">
                <img class="imgs_1" src="@/assets/about/research/项目配图1.png" alt="">
                <img class="imgs_2" src="@/assets/about/research/项目配图2.png" alt="">
            </div>
            <div class="box_2"></div>
            <div class="textImg">
                <img class="img_1" src="@/assets/about/research/项目配图3.png" alt="">
                <div class="div_bottom">
                    <div v-for="item in titleList" :key="item.id">
                        <li style="font-size: 1.5rem;">{{ item.title_top }}</li>
                        <p style="text-indent: -0.3em;">{{ item.title_bottom }}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 底部 -->
        <foot></foot>
    </div>
    <div class="_div">
        <!-- 头部 -->
        <move_header></move_header>
        <!-- 轮播图 -->
        <van-swipe :autoplay="3000" indicator-color="white">
            <van-swipe-item>
                 <img src="@/assets/homePage/关于盛泰banner.png" class="images">
            </van-swipe-item>
        </van-swipe>
        <!-- 内容区域 -->
        <div class="content">
            <h2 class="H2">技术研发</h2>
            <img class="img_1" src="@/assets/about/research/技术研发.png" alt="">
            <div class="box_1"></div>
            <div class="text" v-for="item in dataList" :key="item.id">
                <h3>{{ item.name }}</h3>
                <p>{{ item.title }}</p>
            </div>
            <div class="imgs">
                <img class="imgs_1" src="@/assets/about/research/项目配图1.png" alt="">
                <img class="imgs_2" src="@/assets/about/research/项目配图2.png" alt="">
            </div>
            <div class="box_2"></div>
            <div class="textImg">
                <!-- <img class="img_1" src="@/assets/about/research/项目配图3.png" alt=""> -->
                <div class="div_bottom">
                    <div v-for="item in titleList" :key="item.id">
                        <li>{{ item.title_top }}</li>
                        <p >{{ item.title_bottom }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <move_foot></move_foot>
    </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRoute } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
    components: { foot, headers, move_header, move_foot },

    setup() {
        const route = useRoute();
        const state = reactive({
            dataList: [
                { id: 1, name: '48M+5M双摄模组', title: '4800万像素可对焦摄像头，同500万像素固定焦距摄像头，通过金属支架组合在一起，形成一个组合摄像头。' },
                { id: 2, name: '64M FNO 1.8大光圈模组', title: "6400万像素大光圈，是通过增大镜头大光圈来增加提升进光量、提升夜景暗环境拍照效果。" },
                { id: 3, name: '64M OIS光学防抖模组', title: "6400万像素光学拍照，利用光学防抖原理会提升拍摄视频效果稳定性。" },
                { id: 4, name: '一亿像素大FNO 1.7大光圈模组', title: "—亿像素拥有强大感光能力，图像细节丰富，缩短拍照时间。" },
                { id: 5, name: '二亿像素大FNO 1.97大光圈模组', title: "二亿超高像素拥有强大感光能力，图像细节丰富，缩短拍照时间。" },
            ],
            titleList: [
                { id: 1, title_top: '二级材料评估能力', title_bottom: '材料评测能力，风险识别能力，设计优化能力，新材料预研能力，经验沉淀。' },
                { id: 2, title_top: '开发新技术配套相关软件和算法的能力', title_bottom: "程式模块化，离焦曲线监控测试程序，OIS调试软体开发，漏光测试算法，flare测试程序等。" },
                { id: 3, title_top: '实验室评测能力', title_bottom: "flare评测分析；解析力评测分析；模组IQ评测分析。" },
                { id: 4, title_top: '开发设备，进行设备改造和革新能力', title_bottom: "离焦曲线测试设备.OIS测试设备等。" },
                { id: 5, title_top: '拉通上下游资源进行协同开发的能力', title_bottom: "合作开发SMA马达，G+Plens验证，驱动IC调试开发。" },
                { id: 6, title_top: '新技术，新材料，新设计，新工艺，新方法的预研能力', title_bottom: "48M/50M/64M/OIS已经具备量产能力，潜望/SMAYT/SMA AF/振镜等预研开发中。" },
                { id: 7, title_top: '失效分析能力', title_bottom: "规范失效分析步骤，辅助工程师准确快速找到失效原因，项目复盘，经验沉淀。" },
                { id: 8, title_top: '开发程序完善，PLM系统导入，推进IT化管理', title_bottom: "" },
            ]
        });
        onMounted(() => {
        });
        return {
            ...toRefs(state),
        };
    },
};
</script>

<style scoped lang="less">
.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}

.box {

    width: 100%;

    .content {
        max-width: 1920px;
        margin: 0 auto;
        width: 70%;

        .textImg {
            box-sizing: border-box;
            display: flex;

            .img_1 {
                flex: 1;
                width: 100%;
                max-height: 800px;
                height: auto;
                padding: 30px 30px 30px 0;
                box-sizing: border-box;
            }

            .div_bottom {
                flex: 3;
                padding: 30px 0;
                li {
                    font-size: 25px;
                    font-weight: bold;
                    margin: 10px 0;
                }

                p {
                    font-size: 20px;
                    margin: 0;
                    margin-left: 40px;
                    margin-bottom: 30px;
                }
            }
        }

        .imgs {
            width: 100%;
            height: 500px;
            box-sizing: border-box;

            .imgs_1 {
                height: 100%;
                width: 30%;
            }

            .imgs_2 {
                height: 100%;
                padding-left: 60px;
                width: 65%;
            }
        }

        .text {
            position: relative;
            width: 100%;
            height: 100px;
            margin: 20px 0;
            padding-left: 100px;
            h2{
                margin: 0;
            }
            p{  
                margin: 10px 0;
                font-size: 20px;
            }
        }

        .text::after {
            content: '';
            position: absolute;
            left: 30px;
            top: 12px;
            width: 40px;
            height: 40px;
            background-color: #302F30;
        }

        .box_1,
        .box_2 {
            position: relative;
            width: 100%;
            height: 40px;
            color: #fff;
            background-color: #EEEEEE;
            margin: 50px 0;
        }

        .box_1::after {
            position: absolute;
            left: 0;
            top: 0;
            content: '项目介绍';
            width: 160px;
            line-height: 65px;
            font-size: 25px;
            height: 65px;
            background-color: #2D71D7;
            font-weight: bold;
            text-align: center;
        }

        .box_2::after {
            position: absolute;
            left: 0;
            top: 0;
            content: '研发能力';
            width: 160px;
            line-height: 65px;
            font-size: 25px;
            height: 65px;
            background-color: #2D71D7;
            font-weight: bold;
            text-align: center;
        }

        .img_1 {
            width: 100%;
        }

        .H2 {
            margin-top: 50px;
            font-size: 1.7rem;
        }

        .H2::before {
            content: "";
            width: 30%;
            height: 2px;
            background-color: #c7000b;
            position: absolute;
            margin-top: 40px;
            left: 18%;
            transform: translateX(-50%);
            // top:55px;
            min-width: 35px;
            max-width: 35px;
        }
    }
}

.images {
    width: 100%;
    height: auto;
    max-height: 600px;
}

::v-deep .el-carousel {
    max-width: 1920px;
    margin: 0 auto;
    overflow-y: hidden;
    height: auto;
    max-height: 600px;
    // max-height: 700px;
}

::v-deep .el-carousel__container {
    height: 100% !important;
    min-height: 500px;
}

.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}

::v-deep .el-card {
    border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
    .box {
        display: block;
    }

    ._div {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .box {
        display: none;
    }

    ._div {
        display: block;

        .content {
       
            width: 100%;
            padding: 30px;
            box-sizing: border-box;
            .textImg {
                box-sizing: border-box;
                display: flex;

                .img_1 {
                    flex: 1;
                    width: 100%;
                    max-width: 700px;
                    max-height: 100%;
                    padding: 30px 30px 30px 0;
                    box-sizing: border-box;
                }

                .div_bottom {
                    flex: 3;
                    padding: 30px 0;

                    li {
                        font-size: 50px;
                        font-weight: bold;
                    }

                    p {
                        font-size: 45px;
                         margin-left: 70px;
                    }
                }
            }

            .imgs {
                // width: 98%;
                height: 800px;
                box-sizing: border-box;
                margin-top: 100px;
                .imgs_1 {
                    height: 100%;
                    width: 33%;
                }

                .imgs_2 {
                    height: 100%;
                    padding-left: 30px;
                    width: 65%;
                    
                }
            }

            .text {
                position: relative;
                
                width: 80%;
                padding-left: 300px;
                height: auto;
                margin: 100px 0;
                h3{
                    font-size: 70px;
                    margin: 20px 0;
                }
                p{
                    margin: 0;padding: 0;
                    font-size: 50px;
                }
            }

            .text::after {
                content: '';
                position: absolute;
                left: 110px;
                top: 80px;
                width: 100px;
                height: 100px;
                background-color: #302F30;
            }

            .box_1,
            .box_2 {
                position: relative;
                width: 100%;
                height: 170px;
                background-color: #EEEEEE;
                margin: 50px 0;
                margin-bottom: 150px;
            }
     
            .box_1::after {
                position: absolute;
                left: 0;
                top: 0;
                content: '项目介绍';
                width: 500px;
                line-height: 240px;
                font-size: 80px;
                height: 240px;
                background-color: #2D71D7;
                font-weight: bold;
                color: #fff;
                text-align: center;
            }

            .box_2::after {
           position: absolute;
                left: 0;
                top: 0;
                content: '项目介绍';
                width: 500px;
                line-height: 240px;
                font-size: 80px;
                color: #fff;
                height: 240px;
                background-color: #2D71D7;
                font-weight: bold;
                text-align: center;
            }

            .img_1 {
                width: 100%;
            }
                  h2 {
        text-align: center;
        font-size: 80px;
      }
      h2::before {
        content: "";
        width: 30%;
        height: 8px;
        background-color: #c7000b;
        position: absolute;
        margin-top: 100px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100px;
        max-width: 100px;
      }
        }
    }
}
</style>